import moment from 'm';
import numeral from 'numeral';

import TruncateString from 'utils/truncate-string.js';


let toggleStates = {};
const cv = window.localStorage.getItem('outstanding-invoices:toggleStates');
if (cv) {
  toggleStates = JSON.parse(cv);
}

const BUCKETS = [
  {title: 'To be sent for authority', eval: j => j.quoteDate === null},
  {
    title: 'Waiting for authority',
    eval: j => j.authoriseDate === null && j.authorityDeclineDate === null,
  },
  {
    title: 'Authority declined',
    eval: j => j.authoriseDate === null && j.authorityDeclineDate !== null,
  },
  {title: 'To be invoiced', eval: j => j.invoiceDate === null},
  {
    title: 'Waiting for payment authority',
    eval: j => j.paymentAuthoriseDate === null && j.paymentDeclineDate === null,
    wait: (j, ts) => Math.max(
      0,
      Math.min(
        4,
        Math.floor((ts - j.invoiceDate) / 3600 / 24 / 30)
      )
    ),
  },
  {
    title: 'Payment declined',
    eval: j => j.paymentAuthoriseDate === null && j.paymentDeclineDate !== null,
  },
  {
    title: 'Waiting for payment',
    eval: j => true,
    wait: (j, ts) => Math.min(4, Math.floor((ts - j.invoiceDate) / 3600 / 24 / 30)),
  },
];

export default (state = {
  notFound: null,
  debtors: [],
  totalStr: null,
  waiting: [],
  waitingTitles: [
    '<30 days',
    '30+ days',
    '60+ days',
    '90+ days',
    '120+ days',
  ],
  toggleStates,
}, action) => {
  switch (action.type) {
    case 'OUTSTANDING-INVOICES-SET-NOT-FOUND':
      return {
        ...state,
        notFound: true,
        debtors: [],
        totalStr: null,
        waiting: [0, 0, 0, 0],
      };
    case 'OUTSTANDING-INVOICES-SET':
    {
      const {jobs} = action;
      const now = Math.round(new Date().valueOf() / 1000);
      const {list: debtors, total, waiting: _waiting} = jobs.reduce((acc, j) => {
        if (!acc.index.hasOwnProperty(j.debtorId)) {
          acc.index[j.debtorId] = acc.list.length;
          acc.list.push({
            id: j.debtorId,
            locations: [],
            total: 0,
            waiting: [0, 0, 0, 0, 0],
          });
        }

        const debtor = acc.list[acc.index[j.debtorId]];

        const lid = [j.debtorId, j.locationId].join(':');
        if (!acc.lidIndex.hasOwnProperty(lid)) {
          acc.lidIndex[lid] = debtor.locations.length;
          debtor.locations.push({
            id: j.locationId,
            buckets: BUCKETS.map(b => ({
              ...b,
              jobs: b.wait ? undefined : [],
              waits: b.wait ? [[], [], [], [], []] : undefined,
              total: 0,
            })),
            total: 0,
            waiting: [0, 0, 0, 0, 0],
          });
        }

        const location = debtor.locations[acc.lidIndex[lid]];

        for (const b of location.buckets) {
          if (b.eval(j)) {
            const _j = {
              ...j,
              makeModel: TruncateString([j.make || '', j.model || ''].join(' ')),
              subtotalStr: numeral(j.subtotal / 100).format('$0,0.00'),
              invoiceDateStr: j.invoiceDate ? moment(j.invoiceDate * 1000).format('DD-MM') : null,
            };
            b.total += j.subtotal;
            if (b.wait) {
              const idx = b.wait(j, now);
              b.waits[idx].push(_j);
              location.waiting[idx] += j.subtotal;
              debtor.waiting[idx] += j.subtotal;
              acc.waiting[idx] += j.subtotal;
            } else {
              b.jobs.push(_j);
            }
            break;
          }
        }

        location.total += j.subtotal;
        debtor.total += j.subtotal;

        acc.total += j.subtotal;

        return acc;
      }, {
        list: [],
        index: {},
        lidIndex: {},
        total: 0,
        waiting: [0, 0, 0, 0, 0],
      });

      debtors.forEach(d => {
        d.locations.sort((a, b) => (b.total - a.total));
        d.totalStr = numeral(d.total / 100).format('$0,0.00');
        d.locations.forEach(l => {
          l.totalStr = numeral(l.total / 100).format('$0,0.00');
          l.buckets.forEach(b => {
            if (b.waits !== undefined) {
              b.waits.forEach(w => {
                w.sort((a, b) => {
                  if (a.invoiceDate !== null && b.invoiceDate !== null) {
                    return a.invoiceDate - b.invoiceDate;
                  } else if (a.quoteDate !== null && b.quoteDate !== null) {
                    return a.quoteDate - b.quoteDate;
                  }
                  return b.subtotal - a.subtotal
                });
              });
            }
            if (b.jobs !== undefined) {
              b.jobs.sort((a, b) => {
                if (a.invoiceDate !== null && b.invoiceDate !== null) {
                  return a.invoiceDate - b.invoiceDate;
                } else if (a.quoteDate !== null && b.quoteDate !== null) {
                  return a.quoteDate - b.quoteDate;
                }
                return b.subtotal - a.subtotal
              });
            }
            b.totalStr = numeral(b.total / 100).format('$0,0.00');
          });
          l.waiting = l.waiting
            .map(v => v
              ? numeral(v / 100).format('$0,0.00')
              : '$0.00'
            );
        });
        d.waiting = d.waiting
          .map(v => v
            ? numeral(v / 100).format('$0,0.00')
            : '$0.00'
          );
      });
      const waiting = _waiting
        .map(v => v
          ? numeral(v / 100).format('$0,0.00')
          : '$0.00'
        );
      debtors.sort((a, b) => b.total - a.total);
      return {
        ...state,
        notFound: false,
        debtors,
        total,
        totalStr: numeral(total / 100).format('$0,0.00'),
        waiting,
      };
    }
    case 'OUTSTANDING-INVOICES-TOGGLE':
    {
      const {debtorId, locationId, sectionId, waitId} = action;
      const id = [debtorId, locationId, sectionId, waitId].filter(f => f !== null).join(':');
      const newToggleStates = {
        ...state.toggleStates,
        [id]: !state.toggleStates[id]
      };
      window.localStorage.setItem('outstanding-invoices:toggleStates', JSON.stringify(newToggleStates));
      return {
        ...state,
        toggleStates: newToggleStates,
      };
    }
    default:
      return state;
  }
};
