import React from 'react';
import * as Icons from 'react-feather';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import { Redirect } from 'react-router-dom'

import colors from 'colors';


const SearchInputContainer = styled.div`
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 0.25rem;
  line-height: 1.5;
  background-color: ${opts => opts.background ? opts.background : 'white'};
  & svg {
    stroke: ${colors.body};
  }
`;
const SearchInput = styled.input`
  background-color: ${opts => opts.background ? opts.background : 'inherit'};
  color: ${colors.body};
  border: none;
  :focus {
    outline: 0;
  }
  ::placeholder {
    color: ${colors.body};
  }
`;
const SearchResults = styled.div`
  max-height: 400px;
  overflow: scroll;
  z-index: 999;
  box-shadow: 1px 1px 10px ${colors.grey4};
`;
const Button = styled.div`
  cursor: pointer;
`;
const JobCard = styled.div`
  cursor: pointer;
  font-size: 0.9rem;
  border-bottom: 1px solid ${colors.grey1};
  :hover {
    background: ${colors.grey1};
  }
  :last-of-type {
    margin-bottom: 0px !important;
    border-bottom: 0px !important;
  }
`;

const OCRInput = styled.label`
  position: absolute !important;
  right: 0 !important;
  font-size: 0.63rem;
  margin: 0 auto;
  & svg {
    stroke: ${colors.body};
  }
`;

const JobField = styled.div`
  @media (max-width: 768px) {
    width: ${opts => opts.width}px;
  }
  @media (max-width: 400px) {
    width: ${opts => opts.width / 2}px;
  }
`;
const JobImg = styled.img`
  border-radius: 0.25rem;
  margin-left: 0px !important;
  max-height: 48px;
  width: auto;
`;

const Result = ({job, onSelect}) => (
  <JobCard className='p-2' onClick={onSelect}>
    <div className='d-flex flex-row'>
      <div className='d-flex flex-column justify-content-between flex-grow-1 pr-2'>
        <div className='d-flex flex-row justify-content-between'>
          <div><strong className='text-body'>{job.jobNumber}</strong></div>
          <div className='text-center'>{job.rego}</div>
        </div>
        <div className='d-flex flex-row justify-content-between'>
          <JobField className='text-truncate' width={100}>{job.vin}</JobField>
          <JobField className='text-right text-truncate' width={100}>{job.claimNumber}</JobField>
        </div>
        <div className='d-flex flex-row justify-content-between'>
          <JobField className='text-truncate' width={100}>{job.name}</JobField>
          <JobField className='text-right text-truncate text-uppercase' width={100}>{job.makeModel}</JobField>
        </div>
        {(job.excessInvoiceNumber !== null || job.invoiceNumber !== null) && (
          <div className='d-flex flex-row justify-content-between'>
            {job.excessInvoiceNumber !== null && (
              <div>Xinv#: {job.excessInvoiceNumber}</div>
            )}
            {job.invoiceNumber !== null && (
              <div>Inv#: {job.invoiceNumber}</div>
            )}
          </div>
        )}
      </div>
      {job.image64 && (
        <div className='d-flex align-items-center'>
          <JobImg src={job.image64} />
        </div>
      )}
    </div>
  </JobCard>
);

export default class SearchBar extends React.Component {
  constructor(opts) {
    super(opts);
    this.state = {
      value: '',
      results: [],
      exactMatch: false
    };
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.searchBar = null;
    this.searchResults = React.createRef();
    this.searchTimeout = null;
  }
  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value,
        results: [],
        exactMatch: false
      });
    }
  }
  onDocumentClick(e) {
    if (this.searchResults.current !== null) {
      const node = ReactDOM.findDOMNode(this.searchResults.current);
      if (!node.contains(e.target) && !this.searchBar.contains(e.target)) {
        this.setState({
          value: '',
          results: [],
          exactMatch: false
        });
      }
    }
  }
  onChange(e) {
    this.setState({
      value: e.target.value
    });
    if (this.searchTimeout === null) {
      this.searchTimeout = setTimeout(() => {
        this.searchTimeout = null;
        if (this.state.value.length >= 3) {
          const {onSearch} = this.props;
          onSearch(this.state.value)
            .then(resp => {
              if (resp.error === true) {
                toast.error('Error encountered while searching.');
              } else {
                this.setState({
                  ...this.state,
                  results: resp.results,
                  exactMatch: false
                });
              }
            });
        } else {
          this.setState({
            ...this.state,
            results: [],
            exactMatch: false
          });
        }
      }, 200);
    }
  }
  onClear() {
    if (this.searchTimeout !== null) {
      clearTimeout(this.searchTimeout);
    }
    this.setState({
      value: '',
      results: [],
      exactMatch: false
    });
    if (this.props.onClear) {
      this.props.onClear();
    }
  }
  onSelect(job) {
    this.setState({
      value: '',
      results: [],
      exactMatch: false
    });
    this.props.onSelect(job);
  }

  onUpload(e) {
    e.preventDefault();
    const {onOcr} = this.props;
    let that = this;

    const images = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (['image/png', 'image/jpeg'].indexOf(files[i].type) !== -1) {
        images.push(files[i]);
      }
    }
    if (images.length > 0) {
      console.log('before resize: ' + images[0].size)
      // resizing img below then upload
      const img = document.createElement("img");
      const reader = new FileReader();
      reader.onload = function(e) {
        img.src = e.target.result;
        img.onload = function () {
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 600;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          const quality = 0.5;
          canvas.toBlob((blob) => {
            console.log(`after resize with quality ${quality}: ` + blob.size)
            onOcr(blob).then(resp => {
              if (resp.error === true) {
                toast.error('Error encountered while ocr.');
              } else {
                const exactMatch = resp.results.filter(r => r.rego === resp.rego).length === 1 && resp.results[0].rego === resp.rego;
                that.setState({
                  ...that.state,
                  results: resp.results,
                  value: resp.rego,
                  exactMatch
                });
              }
            });
          }, 'image/jpeg', quality);
        }
      }
      reader.readAsDataURL(images[0]);
    }
    e.target.value = null;
  }

  render() {
    const {background, showPlaceholder, canSearch} = this.props;
    if (canSearch !== true) return null;
    const {value, results, exactMatch} = this.state;
    return (
      <div className='position-relative'>
        <SearchInputContainer
          ref={ref => this.searchBar = ref}
          background={background}
          className='d-flex flex-row justify-content-between align-items-center rounded'
        >
          {showPlaceholder === true && (
            <Button className='p-2'>
              <Icons.Search width={20} height={20} />
            </Button>
          )}
          <SearchInput
            background={background}
            className='flex-grow-1'
            type='text'
            placeholder={showPlaceholder && 'search'}
            value={value}
            onChange={this.onChange}
          />
          {value.length > 0 && (
            <Button className='p-2' onClick={this.onClear}>
              <Icons.X width={20} height={20} />
            </Button>
          )}
          {value.length === 0 && (
            <OCRInput className='btn p-2' htmlFor={`ocrinputsearch`}>
              <Icons.Camera width={20} height={20} />
              <input
                id={`ocrinputsearch`}
                className='d-none'
                type='file'
                accept='image/*'
                onChange={this.onUpload}
              />
            </OCRInput>
          )}
        </SearchInputContainer>
        {results.length > 0 && exactMatch !== true && (
          <SearchResults ref={this.searchResults} className='position-absolute border bg-white w-100'>
            {results.map((job, i) => (
              <Result key={i} job={job} onSelect={this.onSelect.bind(this, job)} />
            ))}
          </SearchResults>
        )}
        {results.length > 0 && exactMatch === true && (
          <Redirect to={{pathname: `/job/${results[0].id}`}} exact={true} />
        )}
      </div>
    );
  }
};
